<template>
    <div>
        <!-- The main navbar div -->
        <nav v-if="!sidebar" class="main-nav">
            <ul class="nav-list">
                <li class="nav-item-left nav-icon fa-2xl"><router-link to="/"><font-awesome-icon icon="code-compare"/> Tender Matcher </router-link></li>
                <li class="nav-item-left nav-item nav-item-home"><router-link to="/">Home</router-link></li>
                <li class="nav-item-left nav-item"><a @click="showModal('export')">Export</a></li>
                <li class="nav-item-right nav-item"><a class="active" @click="logout()">Logout</a></li>
            </ul>
        </nav>

        <!-- The export modal popup -->
        <Transition>
            <ExportModal :json-data-short="json_data_short" :json-data-long="json_data_long" v-show="isExportModalVisible" @close="closeModal('export')"/>
        </Transition>

        <!-- The settings modal popup -->
        <Transition>
            <SettingsModal v-show="isSettingsModalVisible"
                @close="closeModal('settings')" @update:parent="sidebar = $event"
                @update:parent2="hideFooter = $event"
            />
        </Transition>

        <div class="container">
            <!-- The first sub-navbar div -->
            <div v-if="!sidebar" class="sub-nav">
                <!-- The progress donut graph -->
                <div class="donut-container">
                    <vc-donut
                        :size="75"
                        :thickness="30"
                        :auto-adjust-text-size="false"
                        :sections="tender_progress_chart">
                        {{ this.total_tender_progress }}%
                    </vc-donut>
                </div>

                <!-- The progress split into divs -->
                <div class="btn-status-container">
                    <button @click="buttonStatusFilter(0)" class="btn-status-filter" :class="{'active': statusFilter == 0}">
                        to process ({{ this.tender_progress_perc[0] }}%)
                    </button>
                    <button @click="buttonStatusFilter(1)" class="btn-status-filter" :class="{'active': statusFilter == 1}">
                        matched ({{ this.tender_progress_perc[1] }}%)
                    </button>
                    <button @click="buttonStatusFilter(2)" class="btn-status-filter" :class="{'active': statusFilter == 2}">
                        no matches ({{ this.tender_progress_perc[2] }}%)
                    </button>
                    <button @click="buttonStatusFilter(3)" class="btn-status-filter" :class="{'active': statusFilter == 3}">
                        auto match ({{ this.tender_progress_perc[3] }}%)
                    </button>
                </div>

                <!-- <div class="bulk-action-container">
                    <span>5 Selected</span>
                    <button class="btn-bulk-action">bulk action</button>
                </div> -->

                <!-- The right part of the sub-navbar -->
                <div class="subnav-rightfloat">
                    <!-- The customize layout settings div -->
                    <div class="cst-layout-container" @click="showModal('settings')">
                        <span class="subnav-rightfloat-text">Customize <br /> layout</span>
                        <font-awesome-icon class="customize-icon fa-2xl" icon="sliders"/>
                    </div>

                    <!-- The pagination div -->
                    <vue-awesome-paginate
                        :total-items=getFilteredMatches.length
                        :items-per-page=getItemsPerPage
                        :max-pages-shown="3"
                        :current-page="1"
                        :on-click="onClickHandler"
                    >
                        <template #prev-button>
                            <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="black"
                                width="8"
                                height="8"
                                viewBox="0 0 24 24"
                            >
                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                            </svg>
                            Prev
                            </span>
                        </template>

                        <template #next-button>
                            <span>
                            Next
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="black"
                                width="8"
                                height="8"
                                viewBox="0 0 24 24"
                            >
                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                            </svg>
                            </span>
                        </template>
                    </vue-awesome-paginate>

                    <!-- The pagination options div -->
                    <div class="paginate-options">
                        <span class="subnav-rightfloat-text">
                            Show per <br />page
                        </span>

                        <div class="custom-select">
                            <vue-select @selected="setPageOne"
                                v-model="selectedOption" 
                                :options="displayOptions"
                                :placeholder="selectedOption"
                                close-on-select
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- The vertical sidebar div -->
            <nav v-if="sidebar" class="vert-nav">
                <ul>
                    <li class="vert-nav-logo-container">
                        <router-link to="/" class="vert-nav-logo">
                            <font-awesome-icon icon="code-compare"/>
                            <span>Tender<br>Matcher</span>
                        </router-link>
                    </li>
                    <li class="vert-nav-mainmenu-container" :class="{'active': statusFilter == 0}">
                        <a @click="buttonStatusFilter(0)" class="vert-nav-mainmenu">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#ffffff" fill="none"><path d="M45.56 46.83v9.43H7.94V20.6L19.9 7.74h25.66v13.55"></path><path d="M19.92 7.74L19.9 20.6H7.94M13.09 47.67H31.1M13.09 41.14H29.1M13.09 35.04H33.1M13.09 28.94H39.1M34.45 43.23l.15 4.3a.49.49 0 00.62.46l4.13-1.11a.54.54 0 00.34-.23l18.07-24.44a1.23 1.23 0 00-.26-1.72l-3.14-2.34a1.22 1.22 0 00-1.72.26L34.57 42.84a.67.67 0 00-.12.39zM50.2 21.7l5.07 3.87"></path></svg> -->
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#ffffff" fill="none"><path d="M53.16 46.18l.76 6.71A1 1 0 0152.81 54l-6.67-.77a1 1 0 01-.59-.29l-34.89-35a2 2 0 010-2.83l4.49-4.51a2 2 0 012.84 0l34.88 35a1 1 0 01.29.58zM22.69 15.31l-7.23 7.44"></path></svg>
                            <span>To process</span>
                        </a>
                    </li>
                    <li class="vert-nav-mainmenu-container" :class="{'active': statusFilter == 1}">
                        <a @click="buttonStatusFilter(1)" class="vert-nav-mainmenu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#ffffff" fill="none"><rect x="8.33" y="10.82" width="47.34" height="34.38" rx="2.02"></rect><path d="M8.33 38.66h47.34M23.05 53.18a6.67 6.67 0 003.55-8M41.84 53.18a6.66 6.66 0 01-3.55-8"></path><path stroke-linecap="round" d="M17.5 53.18h29"></path><path d="M16.8 19.18l2.9 2.03 3.59-5.05M16.38 28.98L19.27 31l3.59-5.05M28.6 19.48h20.82M28.6 29.66h20.82"></path></svg>
                            <span>Matched</span>
                        </a>
                    </li>
                    <li class="vert-nav-mainmenu-container" :class="{'active': statusFilter == 2}">
                        <a @click="buttonStatusFilter(2)" class="vert-nav-mainmenu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#ffffff" fill="none"><path d="M34.48 54.28H11.06V18.61L23.02 5.75h25.65v33.67"></path><path d="M23.04 5.75l-.02 12.86H11.06M16.21 45.68h12.01M16.21 39.15h15.01M16.21 33.05h27.01M16.21 26.95h27.01"></path><circle cx="42.92" cy="48.24" r="10.01" stroke-linecap="round"></circle><path d="M39.05 44.36l7.75 7.75M39.05 52.11l7.75-7.75"></path></svg>
                            <span>No match</span>
                        </a>
                    </li>
                    <li class="vert-nav-mainmenu-container" :class="{'active': statusFilter == 3}">
                        <a @click="buttonStatusFilter(3)" class="vert-nav-mainmenu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#ffffff" fill="none"><path d="M52.94 42.93V18.3a5.54 5.54 0 00-5.54-5.54H11.83M11.83 20.14v24.63a5.54 5.54 0 005.54 5.54h35.57"></path><path d="M4.15 26.39l7.94-6.25 7.42 6.74M60.36 36.12l-7.45 6.82L45 36.76"></path></svg>
                            <span>Auto match</span>
                        </a>
                    </li>
                    <li class="vert-nav-donut">
                        <vc-donut
                            background="#036"
                            :size="90"
                            :thickness="30"
                            :auto-adjust-text-size="false"
                            :sections="tender_progress_chart">
                            {{ this.total_tender_progress }}%
                        </vc-donut> 
                    </li>
                </ul>

                <div class="vert-nav-submenu-container">
                    <a title="export" @click="showModal('export')" class="vert-nav-submenu">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="2.5" stroke="#FFFFFF" fill="none"><path stroke-linecap="round" d="M25.15 6.32h25.66v48.52H13.19V19.18L25.15 6.32z"></path><path d="M25.17 6.32l-.02 12.86H13.19M40.26 34v7.4a.82.82 0 01-.82.81H24.56a.82.82 0 01-.82-.81V34M36.08 30.87L32 26.79l-4.07 4.08M32 26.79v11.95"></path></svg>
                    </a>

                    <a title="settings" @click="showModal('settings')" class="vert-nav-submenu">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#ffffff" fill="none"><path d="M45 14.67l-2.76 2a1 1 0 01-1 .11l-3.59-1.48a1 1 0 01-.61-.76l-.66-3.77a1 1 0 00-1-.84h-4.86a1 1 0 00-1 .77l-.93 3.72a1 1 0 01-.53.65l-3.3 1.66a1 1 0 01-1-.08l-3-2.13a1 1 0 00-1.31.12l-3.65 3.74a1 1 0 00-.13 1.26l1.87 2.88a1 1 0 01.1.89L16.34 27a1 1 0 01-.68.63l-3.85 1.06a1 1 0 00-.74 1v4.74a1 1 0 00.8 1l3.9.8a1 1 0 01.72.57l1.42 3.15a1 1 0 01-.05.92l-2.13 3.63a1 1 0 00.17 1.24L19.32 49a1 1 0 001.29.09L23.49 47a1 1 0 011-.1l3.74 1.67a1 1 0 01.59.75l.66 3.79a1 1 0 001 .84h4.89a1 1 0 001-.86l.58-4a1 1 0 01.58-.77l3.58-1.62a1 1 0 011 .09l3.14 2.12a1 1 0 001.3-.15l3.45-3.7a1 1 0 00.09-1.27l-2.08-3a1 1 0 01-.09-1l1.48-3.43a1 1 0 01.71-.59l3.66-.77a1 1 0 00.8-1v-4.58a1 1 0 00-.8-1l-3.72-.78a1 1 0 01-.73-.62l-1.45-3.65a1 1 0 01.11-.94l2.15-3.14A1 1 0 0050 18l-3.71-3.25a1 1 0 00-1.29-.08z"></path><circle cx="32.82" cy="31.94" r="9.94"></circle></svg>    
                    </a>
                </div>
                
            </nav>
            <div class="matchlist-container" :class="{'matchlist-container-sidebar': sidebar}">
                <table class="table-container" cellspacing="0" :class="{'table-container-horizontal': !sidebar}">
                    <thead :class="sidebar ? 'table-header' : 'table-header-horizontal'">
                        <tr class="non-expandable-rows">
                            <th class="table-bulk" style="padding-top: 45px">
                                <div class="table-bulk-grid">
                                    <label class="table-bulk-checkbox table-bulk-path">
                                        <input type="checkbox" v-model="allChecked">
                                        <svg viewBox="0 0 21 21">
                                            <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                                        </svg>
                                    </label>
                                </div>
                            </th>
                            <th class="table-tender">
                                <div class="table-product-title">
                                    <span class="table-product-span">Tender Product</span>
                                    <!-- <font-awesome-icon class="fa-sm table-product-sort" @click="sortList('tender_product_desc')" :icon="getSortIconTender"/> -->
                                    <div class="table-product-sort">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="7" fill="none" style="width: 10px; height: 10px;"><path d="M57.47 45.15L30.84 19.88 6.58 45.15"></path></svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="7" fill="none" style="width: 10px; height: 10px;"><path d="M6.53 18.86l26.63 25.26 24.26-25.26"></path></svg>
                                    </div>
                                </div>
                                <div class="table-product-search">
                                    <div class="table-search-magnifying">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="5" fill="none" style="width: 20px; height: 20px;"><circle cx="27.31" cy="25.74" r="18.1"></circle><path d="M39.58 39.04L56.14 57"></path></svg>
                                    </div>
                                    <input
                                        @input="setPageOne"
                                        class="table-search-box"
                                        v-model="tenderSearchText"
                                    />
                                </div>
                            </th>
                            <th class="table-database">
                                <div class="table-product-title">
                                    <span class="table-product-span">Database Product</span>
                                    <!-- <font-awesome-icon class="fa-sm table-database-sort" @click="sortList('database_product_desc')" :icon="getSortIconDatabase"/> -->
                                    <div class="table-product-sort">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="7" fill="none" style="width: 10px; height: 10px;"><path d="M57.47 45.15L30.84 19.88 6.58 45.15"></path></svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="7" fill="none" style="width: 10px; height: 10px;"><path d="M6.53 18.86l26.63 25.26 24.26-25.26"></path></svg>
                                    </div>
                                </div>
                                <div class="table-product-search">
                                    <div class="table-search-magnifying">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="5" fill="none" style="width: 20px; height: 20px;"><circle cx="27.31" cy="25.74" r="18.1"></circle><path d="M39.58 39.04L56.14 57"></path></svg>
                                    </div>
                                    <input
                                        @input="setPageOne"
                                        class="table-search-box"
                                        v-model="productSearchText"
                                    />
                                </div>
                            </th>
                            <th class="table-matches">
                                <div class="table-matches-title">
                                    <span class="table-matches-span">Matches</span>
                                </div>
                                <div class="table-matches-features">
                                    <span class="table-features-span" style="width: 65px">Description</span>
                                    <span class="table-features-span" style="width: 40px">ID</span>
                                    <span class="table-features-span" style="width: 40px">Brand</span>
                                    <span class="table-features-span" style="width: 45px">Features</span>
                                </div>
                            </th>
                            <th class="table-status">
                                <div class="table-status-title">
                                    <span class="table-status-span">Status</span>
                                </div>
                                <div class="table-status-bulk">
                                    <div class="table-status">
                                        <div class="table-status-hover-container">
                                            <div class="table-status-hover" :class="{'active': selectedItems.length > 0}">
                                                <a @click="bulkChangeStatus(0)" class="table-change-status yellow" title="To Process">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#001122" fill="none"><path d="M53.16 46.18l.76 6.71A1 1 0 0152.81 54l-6.67-.77a1 1 0 01-.59-.29l-34.89-35a2 2 0 010-2.83l4.49-4.51a2 2 0 012.84 0l34.88 35a1 1 0 01.29.58zM22.69 15.31l-7.23 7.44"></path></svg>
                                                </a>
                                                <a @click="bulkChangeStatus(1)" class="table-change-status blue" title="Match">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#001122" fill="none"><rect x="8.33" y="10.82" width="47.34" height="34.38" rx="2.02"></rect><path d="M8.33 38.66h47.34M23.05 53.18a6.67 6.67 0 003.55-8M41.84 53.18a6.66 6.66 0 01-3.55-8"></path><path stroke-linecap="round" d="M17.5 53.18h29"></path><path d="M16.8 19.18l2.9 2.03 3.59-5.05M16.38 28.98L19.27 31l3.59-5.05M28.6 19.48h20.82M28.6 29.66h20.82"></path></svg>
                                                </a>
                                                <a @click="bulkChangeStatus(2)" class="table-change-status red" title="No Match">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#001122" fill="none"><path d="M34.48 54.28H11.06V18.61L23.02 5.75h25.65v33.67"></path><path d="M23.04 5.75l-.02 12.86H11.06M16.21 45.68h12.01M16.21 39.15h15.01M16.21 33.05h27.01M16.21 26.95h27.01"></path><circle cx="42.92" cy="48.24" r="10.01" stroke-linecap="round"></circle><path d="M39.05 44.36l7.75 7.75M39.05 52.11l7.75-7.75"></path></svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="getPageOfItems.length > 0" class="table-body">
                        <template v-for="tendermatch in getPageOfItems" :key="tendermatch.id">
                            <tr class="non-expandable-rows" :class="{'active': showAdditionalRowsFor.includes(tendermatch.id) || selectedItems.includes(tendermatch.id)}">
                                <td class="table-bulk">
                                    <div class="table-bulk-grid">
                                        <label class="table-bulk-checkbox table-bulk-path">
                                            <input type="checkbox" :value="tendermatch.id" :checked="selectedItems.includes(tendermatch.id)" @change="toggleSelection(tendermatch.id)">
                                            <svg viewBox="0 0 21 21">
                                                <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                                <td class="table-tender">
                                    <span 
                                        v-for="token in tokenize(tendermatch.tender_product_desc, tendermatch.database_matches[0].entity_matches, 'tender')" 
                                        :key="token.value"
                                        :class="[token.value != ' ' ? 'token-base' : 'token-space', token.isEntity ? 'entity' : 'non-entity', getHighlightClass(token, tendermatch.database_matches[0].id), getEntityTypeClass(token)]"
                                        @mouseenter="setHighlightedToken(token, tendermatch.database_matches[0].id)"
                                        @mouseleave="setHighlightedToken(null, null)">
                                        {{ token.value }}
                                    </span>
                                </td>
                                <td class="table-database">
                                    <span 
                                        v-for="token in tokenize(tendermatch.database_matches[0].database_product_desc, tendermatch.database_matches[0].entity_matches, 'database')" 
                                        :key="token.value"
                                        :class="[token.value != ' ' ? 'token-base' : 'token-space', token.isEntity ? 'entity' : 'non-entity', getHighlightClass(token, tendermatch.database_matches[0].id), getEntityTypeClass(token)]"
                                        @mouseenter="setHighlightedToken(token, tendermatch.database_matches[0].id)"
                                        @mouseleave="setHighlightedToken(null, null)">
                                        {{ token.value }}
                                    </span>
                                </td>
                                <td class="table-matches">
                                    <div class="table-matches-featureicons">
                                        <div class="table-matches-description">
                                            <svg v-if="tendermatch.database_matches[0]['no_desc_matches'] > 0" width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 13L10 16L17 9" stroke="#067370" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <svg v-else width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 8L16 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M16 8L8 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="table-matches-ean">
                                            <svg v-if="tendermatch.database_matches[0]['no_id_matches'] > 0" width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 13L10 16L17 9" stroke="#067370" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <svg v-else width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 8L16 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M16 8L8 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="table-matches-brand">
                                            <svg v-if="tendermatch.database_matches[0]['no_brand_matches'] > 0" width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 13L10 16L17 9" stroke="#067370" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <svg v-else width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 8L16 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M16 8L8 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="table-matches-category">
                                            <svg v-if="tendermatch.database_matches[0]['no_material_matches'] > 0" width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 13L10 16L17 9" stroke="#067370" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <svg v-else width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 8L16 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M16 8L8 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </td>
                                <td class="table-status" :class="{'active': showAdditionalRowsFor.includes(tendermatch.id)}">
                                    <div class="table-status-regular">
                                        <a v-if="tendermatch.tender_product_match_status == 0" class="table-status-button yellow">To process</a>
                                        <a v-if="tendermatch.tender_product_match_status == 1" class="table-status-button blue">Matched</a>
                                        <a v-if="tendermatch.tender_product_match_status == 2" class="table-status-button red">No match</a>
                                        <a v-if="tendermatch.tender_product_match_status == 3" class="table-status-button green">Auto match</a>
                                    </div>
                                    <div class="table-status-hover-container">
                                        <div class="table-status-hover">
                                            <a @click="changeStatus(tendermatch, 0)" class="table-change-status yellow" title="To Process">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#001122" fill="none"><path d="M53.16 46.18l.76 6.71A1 1 0 0152.81 54l-6.67-.77a1 1 0 01-.59-.29l-34.89-35a2 2 0 010-2.83l4.49-4.51a2 2 0 012.84 0l34.88 35a1 1 0 01.29.58zM22.69 15.31l-7.23 7.44"></path></svg>
                                            </a>
                                            <a @click="changeStatus(tendermatch, 1)" class="table-change-status blue" title="Match">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#001122" fill="none"><rect x="8.33" y="10.82" width="47.34" height="34.38" rx="2.02"></rect><path d="M8.33 38.66h47.34M23.05 53.18a6.67 6.67 0 003.55-8M41.84 53.18a6.66 6.66 0 01-3.55-8"></path><path stroke-linecap="round" d="M17.5 53.18h29"></path><path d="M16.8 19.18l2.9 2.03 3.59-5.05M16.38 28.98L19.27 31l3.59-5.05M28.6 19.48h20.82M28.6 29.66h20.82"></path></svg>
                                            </a>
                                            <a @click="changeStatus(tendermatch, 2)" class="table-change-status red" title="No Match">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#001122" fill="none"><path d="M34.48 54.28H11.06V18.61L23.02 5.75h25.65v33.67"></path><path d="M23.04 5.75l-.02 12.86H11.06M16.21 45.68h12.01M16.21 39.15h15.01M16.21 33.05h27.01M16.21 26.95h27.01"></path><circle cx="42.92" cy="48.24" r="10.01" stroke-linecap="round"></circle><path d="M39.05 44.36l7.75 7.75M39.05 52.11l7.75-7.75"></path></svg>
                                            </a>
                                            <a @click="toggleAdditionalRow(tendermatch.id)" class="table-change-status purple" :class="[showAdditionalRowsFor.includes(tendermatch.id) ? 'arrow-up' : 'arrow-down']" title="Alternative Matches">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#001122" fill="none"><path d="M6.53 18.86l26.63 25.26 24.26-25.26"></path></svg>
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="expandable-rows">
                                <td colspan="5" :class="{'expandable-rows-td-hidden': !showAdditionalRowsFor.includes(tendermatch.id)}">
                                    <div :ref="`wrapper-${tendermatch.id}`" data-id="tendermatch.id" :class="[showAdditionalRowsFor.includes(tendermatch.id) ? 'expandable-rows-table-wrapper-active' : 'expandable-rows-table-wrapper']">
                                        <table class="nested-table">
                                            <tbody>
                                                <tr v-for="(databasematch, index) in tendermatch.database_matches.slice(1, 6)" :key="databasematch.id">
                                                    <td class="table-bulk">
                                                    </td>
                                                    <td class="table-tender">
                                                        <span 
                                                            v-for="token in tokenize(tendermatch.tender_product_desc, databasematch.entity_matches, 'tender')" 
                                                            :key="token.value"
                                                            :class="[token.value != ' ' ? 'token-base' : 'token-space', token.isEntity ? 'entity' : 'non-entity', getHighlightClass(token, databasematch.id), getEntityTypeClass(token)]"
                                                            @mouseenter="setHighlightedToken(token, databasematch.id)"
                                                            @mouseleave="setHighlightedToken(null, null)">
                                                            {{ token.value }}
                                                        </span>
                                                    </td>
                                                    <td class="table-database">
                                                        <span 
                                                            v-for="token in tokenize(databasematch.database_product_desc, databasematch.entity_matches, 'database')" 
                                                            :key="token.value"
                                                            :class="[token.value != ' ' ? 'token-base' : 'token-space', token.isEntity ? 'entity' : 'non-entity', getHighlightClass(token, databasematch.id), getEntityTypeClass(token)]"
                                                            @mouseenter="setHighlightedToken(token, databasematch.id)"
                                                            @mouseleave="setHighlightedToken(null, null)">
                                                            {{ token.value }}
                                                        </span>
                                                    </td>
                                                    <td class="table-matches">
                                                        <div class="table-matches-featureicons">
                                                            <div class="table-matches-description">
                                                                <svg v-if="databasematch['no_desc_matches'] > 0" width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7 13L10 16L17 9" stroke="#067370" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                <svg v-else width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8 8L16 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M16 8L8 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                            <div class="table-matches-ean">
                                                                <svg v-if="databasematch['no_id_matches'] > 0" width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7 13L10 16L17 9" stroke="#067370" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                <svg v-else width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8 8L16 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M16 8L8 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                            <div class="table-matches-brand">
                                                                <svg v-if="databasematch['no_brand_matches'] > 0" width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7 13L10 16L17 9" stroke="#067370" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                <svg v-else width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8 8L16 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M16 8L8 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                            <router-link :to="`/tender/matches/${tendermatch.id}`">
                                                                <div class="table-matches-category">
                                                                    <svg v-if="databasematch['no_cat_matches'] > 0" width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M7 13L10 16L17 9" stroke="#067370" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                    <svg v-else width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M8 8L16 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M16 8L8 16" stroke="#730606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </div>
                                                            </router-link>
                                                        </div>
                                                    </td>
                                                    <td class="table-status">
                                                        <div class="table-status-hover-container">
                                                            <div class="table-status-hover">
                                                                <a @click="changeOrder(index + 1, tendermatch, 0)" class="table-change-status yellow" title="To Process">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#001122" fill="none"><path d="M53.16 46.18l.76 6.71A1 1 0 0152.81 54l-6.67-.77a1 1 0 01-.59-.29l-34.89-35a2 2 0 010-2.83l4.49-4.51a2 2 0 012.84 0l34.88 35a1 1 0 01.29.58zM22.69 15.31l-7.23 7.44"></path></svg>
                                                                </a>
                                                                <a @click="changeOrder(index + 1, tendermatch, 1)" class="table-change-status blue" title="Match">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#001122" fill="none"><rect x="8.33" y="10.82" width="47.34" height="34.38" rx="2.02"></rect><path d="M8.33 38.66h47.34M23.05 53.18a6.67 6.67 0 003.55-8M41.84 53.18a6.66 6.66 0 01-3.55-8"></path><path stroke-linecap="round" d="M17.5 53.18h29"></path><path d="M16.8 19.18l2.9 2.03 3.59-5.05M16.38 28.98L19.27 31l3.59-5.05M28.6 19.48h20.82M28.6 29.66h20.82"></path></svg>
                                                                </a>
                                                                <a @click="changeOrder(index + 1, tendermatch, 2)" class="table-change-status red" title="No Match">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="3" stroke="#001122" fill="none"><path d="M34.48 54.28H11.06V18.61L23.02 5.75h25.65v33.67"></path><path d="M23.04 5.75l-.02 12.86H11.06M16.21 45.68h12.01M16.21 39.15h15.01M16.21 33.05h27.01M16.21 26.95h27.01"></path><circle cx="42.92" cy="48.24" r="10.01" stroke-linecap="round"></circle><path d="M39.05 44.36l7.75 7.75M39.05 52.11l7.75-7.75"></path></svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-else>
                        <h2 style="padding-top: 200px; padding-left: 700px; width: 100px;">No matches found</h2>
                    </tbody>
                </table>
            </div>
            <footer v-if="sidebar" class="matches-dashboard-footer" :class="{'footer-hidden': hideFooter}">
                <div class="float-right">
                    <!-- The pagination div -->
                    <vue-awesome-paginate
                        :total-items=getFilteredMatches.length
                        :items-per-page=getItemsPerPage
                        :max-pages-shown="3"
                        :current-page="1"
                        :on-click="onClickHandler"
                    >
                        <template #prev-button>
                            <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="black"
                                width="8"
                                height="8"
                                viewBox="0 0 24 24"
                            >
                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                            </svg>
                            Prev
                            </span>
                        </template>

                        <template #next-button>
                            <span>
                            Next
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="black"
                                width="8"
                                height="8"
                                viewBox="0 0 24 24"
                            >
                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                            </svg>
                            </span>
                        </template>
                    </vue-awesome-paginate>

                    <!-- The pagination options div -->
                    <div class="paginate-options">                        
                        <span class="footer-rightfloat-text">
                            Show
                        </span>

                        <div class="custom-select">
                            <vue-select @selected="setPageOne"
                                v-model="selectedOption" 
                                :options="displayOptions"
                                :placeholder="selectedOption"
                                close-on-select
                            />
                        </div>
                    </div>
                    
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useToast } from "vue-toastification";
import ExportModal from "./ExportModal.vue"
import SettingsModal from "./SettingsModal.vue"


export default {
    name: "MatchesDashboard",
    components: { ExportModal, SettingsModal },
    setup() {
        const tenderSearchText = ref('')
        const productSearchText = ref('')

        // Get the toast interface
        const toast = useToast();

        return {
            tenderSearchText,
            productSearchText,
            toast
        }
    },
    data() {
        return {
            // Important info for dashboard
            token: '',
            tendermatches: [],
            tenderID: this.$route.params.tender,
            // Data used during export.
            json_data_short: [],
            json_data_long: [],
            // Modal info
            isExportModalVisible: false,
            isSettingsModalVisible: false,
            // Boolean used for the keyword match hovering.
            matchHover: false,
            // Data for the progress donut
            tender_progress_chart: [],
            tender_progress_nums: [],
            tender_progress_perc: [],
            total_tender_progress: 0,
            // Info for pagination and filtering
            curPage: 1,
            displayOptions: ['50', '100', '200', 'all'],
            selectedOption: '50',
            statusFilter: 0,
            // Vertical sidebar boolean
            sidebar: true,
            hideFooter: false,
            // Sorting
            sortBy: 'no_total_matches',
            sortDirection: 'asc',
            // Track the current highlighted token
            highlightedToken: null,
            // The alternative matches rows
            showAdditionalRowsFor: [],
            // The data for the bulk action
            selectedItems: [],
        }
    },
    computed: {
        // Handles bulk action
        allChecked: {
            get() {
                return this.getPageOfItems.length > 0 && this.selectedItems.length === this.getPageOfItems.length;
            },
            set(newValue) {
                if (newValue) {
                    this.selectedItems = this.getPageOfItems.map(tendermatch => tendermatch.id);
                } else {
                    this.selectedItems = [];
                }
            }
        },
        // This function should handle all the tender matches filtering.
        getFilteredMatches() {
            let filteredTMs = this.tendermatches
            // The tender product search field.
            if (this.tenderSearchText.length > 0) {
                filteredTMs = filteredTMs.filter(tender =>
                    tender.tender_product_desc.toLowerCase().includes(this.tenderSearchText.toLowerCase())
                )
            }
            // The database product search field.
            if (this.productSearchText.length > 0) {
                filteredTMs = filteredTMs.filter(tender =>
                    tender.database_matches[0]['database_product_desc'].toLowerCase().includes(this.productSearchText.toLowerCase())
                )
            }
            // The status filter buttons (-1 = show all, 0 = todo, etc).
            if (this.statusFilter >= 0) {
                filteredTMs = filteredTMs.filter(tender =>
                    tender.tender_product_match_status == this.statusFilter
                )
            }

            return filteredTMs
        },
        // This function should handle all the tender matches sorting
        getSortedMatches() {
            let sortedTMs = this.getFilteredMatches
            // The match count sort circles (all start with 'no') and database product sort button.
            if (this.sortBy.substring(0, 2) == 'no' || this.sortBy == 'database_product_desc') {
                sortedTMs = sortedTMs.sort((p1,p2) => {
                    let modifier = 1;
                    if(this.sortDirection === 'desc') modifier = -1;
                    if(p1.database_matches[0][this.sortBy] < p2.database_matches[0][this.sortBy]) return -1 * modifier; if(p1.database_matches[0][this.sortBy] > p2.database_matches[0][this.sortBy]) return 1 * modifier;
                })
            }
            // The tender product sort button.
            else if (this.sortBy == 'tender_product_desc') {
                sortedTMs = sortedTMs.sort((p1,p2) => {
                    let modifier = 1;
                    if(this.sortDirection === 'desc') modifier = -1;
                    if(p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier; if(p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
                })
            }

            return sortedTMs
        },
        // This function handles the pagination of the tender matches.
        getPageOfItems() {
            let showPerPage;
            if (this.selectedOption == 'all') {
                showPerPage = this.tendermatches.length
            } else {
                showPerPage = parseInt(this.selectedOption)
            }
            const start = (this.curPage - 1) * showPerPage
            const end = this.curPage * showPerPage
            return this.getSortedMatches.slice(start, end)
        },
        // This function returns the number of items to display per page.
        getItemsPerPage() {
            if (this.selectedOption == 'all') {
                return this.tendermatches.length
            }
            return parseInt(this.selectedOption)
        },
        getDirectionSort() {
            return (this.sortDirection == 'asc') ? 'fa-solid fa-sort-up' : 'fa-solid fa-sort-down'
        },
        getSortIconTender() {
            return (this.sortBy != 'tender_product_desc') ? 'fa-solid fa-sort' : this.getDirectionSort
        },
        getSortIconDatabase() {
            return (this.sortBy != 'database_product_desc') ? 'fa-solid fa-sort' : this.getDirectionSort
        }
    },
    async created() {
        // On the creation of the Vue component we need to load all the matches of this specific
        // tender. We also get the information stored about the specific tender, such as progress.
        if(this.$cookies.isKey('tm-token')) {
            this.token = this.$cookies.get('tm-token');
            
            // Get the matches of the tender.
            await fetch('https://api.tendermatcher.org/backend_api/tendermatch/' + this.tenderID, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${this.token}`
                }
            }).then(resp => resp.json()).then(resp => this.tendermatches = resp).catch(error => console.log(error))

            // We can't be certain that django returns the data correctly sorted.
            this.tendermatches.forEach((obj) => {
                // Sort the database_matches_array for the current object
                obj.database_matches.sort((a, b) => b.score - a.score);
            });

            let tender_resp;

            // Get all the information about the tender, which is used to display the matching progress.
            await fetch('https://api.tendermatcher.org/backend_api/tender_single/' + this.tenderID, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${this.token}`
                }
            }).then(resp => resp.json())
            .then(resp => tender_resp = resp)
            .catch(error => console.log(error))


            let processPercentage = Math.round(tender_resp.tender_progress_todo / tender_resp.no_of_products * 100)
            let matchedPercentage = Math.round(tender_resp.tender_progress_match / tender_resp.no_of_products * 100)
            let nomatchPercentage = Math.round(tender_resp.tender_progress_nomatch / tender_resp.no_of_products * 100)
            let automatchPercentage = Math.round(tender_resp.tender_progress_auto / tender_resp.no_of_products * 100)
                        
            // Store the number of different product statuses.
            this.tender_progress_nums = [tender_resp.tender_progress_todo, tender_resp.tender_progress_match,
                                         tender_resp.tender_progress_nomatch, tender_resp.tender_progress_auto, tender_resp.no_of_products]
            
            // Store the percentage of different product statuses.
            this.tender_progress_perc = [processPercentage, matchedPercentage, nomatchPercentage, automatchPercentage]
            
            // Store the total progress percentage.   
            this.total_tender_progress = Math.round((tender_resp.tender_progress_auto + tender_resp.tender_progress_match + 
                                          tender_resp.tender_progress_nomatch) / tender_resp.no_of_products * 100)

            // Store the progress percentages for the donut graph.
            this.tender_progress_chart = [{label: 'auto matched ' + this.tender_progress_nums[3] + '/' + this.tender_progress_nums[4],
                                              color: '#56C596', value: automatchPercentage}, 
                                          {label: 'matched ' + this.tender_progress_nums[1] + '/' + this.tender_progress_nums[4],
                                              color: '#5697c5', value: matchedPercentage},
                                          {label: 'no matches ' + this.tender_progress_nums[2] + '/' + this.tender_progress_nums[4],
                                              color: '#c55656', value: nomatchPercentage},
                                          {label: 'todo ' + this.tender_progress_nums[0] + '/' + this.tender_progress_nums[4],
                                              color: '#f2f3f4', value: processPercentage}]

            // this.tender_progress_chart = [{label: 'auto matched ' + this.tender_progress_nums[3] + '/' + this.tender_progress_nums[4],
            //                                   color: '#56C596', value: 22}, 
            //                               {label: 'matched ' + this.tender_progress_nums[1] + '/' + this.tender_progress_nums[4],
            //                                   color: '#5697c5', value: 23},
            //                               {label: 'no matches ' + this.tender_progress_nums[2] + '/' + this.tender_progress_nums[4],
            //                                   color: '#c55656', value: 20},
            //                               {label: 'todo ' + this.tender_progress_nums[0] + '/' + this.tender_progress_nums[4],
            //                                   color: '#f2f3f4', value: 35}]


    // .red {
    //     color: #730606;
    //     background: #f4dede
    // }

    // .blue {
    //     color: #003366;
    //     background: #f1f4f8;
    // }

    // .green {
    //     color: #067306;
    //     background: #e4f5f5;
    // }

            // if (this.sidebar) {
            //     this.tender_progress_chart = [{label: 'auto matched ' + this.tender_progress_nums[3] + '/' + this.tender_progress_nums[4],
            //                                   color: '#56C596', value: 15}, 
            //                               {label: 'matched ' + this.tender_progress_nums[1] + '/' + this.tender_progress_nums[4],
            //                                   color: '#00d2ff', value: 25},
            //                               {label: 'no matches ' + this.tender_progress_nums[2] + '/' + this.tender_progress_nums[4],
            //                                   color: '#EA3C53', value: 30},
            //                               {label: 'todo ' + this.tender_progress_nums[0] + '/' + this.tender_progress_nums[4],
            //                                   color: '#f2f3f4', value: 30}]
            // } else {
            //     this.tender_progress_chart = [{label: 'auto matched ' + this.tender_progress_nums[3] + '/' + this.tender_progress_nums[4],
            //                                   color: '#090', value: 15}, 
            //                               {label: 'matched ' + this.tender_progress_nums[1] + '/' + this.tender_progress_nums[4],
            //                                   color: '#036', value: 25},
            //                               {label: 'no matches ' + this.tender_progress_nums[2] + '/' + this.tender_progress_nums[4],
            //                                   color: '#900', value: 30},
            //                               {label: 'todo ' + this.tender_progress_nums[0] + '/' + this.tender_progress_nums[4],
            //                                   color: '#f2f3f4', value: 30}]
            // }
        } else {
            this.$router.push('/login');
        }
    },
    methods: {
        //The bulk action functions
        toggleSelection(tendermatchId) {
            const index = this.selectedItems.indexOf(tendermatchId);
            if (index === -1) {
            this.selectedItems.push(tendermatchId);
            } else {
            this.selectedItems.splice(index, 1);
            }
        },
        // The function that does the backend based tokenization.
        tokenize(description, entityMatches, mode) {
            let tokens = [];
            let lastPos = 0;

            // Flatten the entityMatches to account for multiple positions
            let flattenedEntities = [];
            entityMatches.forEach(entity => {
                entity[`${mode}_entity_positions`].forEach(pos => {
                    flattenedEntities.push({
                        value: entity[`${mode}_entity_value`],
                        type: entity[`${mode}_entity_type`],
                        positions: pos
                    });
                });
            });

            // Sort entity matches based on the positions
            flattenedEntities.sort((a, b) => a.positions[0] - b.positions[0]);

            for (let entity of flattenedEntities) {
                let startPos = entity.positions[0];
                let endPos = entity.positions[1];

                if (lastPos < startPos) {
                    tokens.push({ value: description.substring(lastPos, startPos) });
                }

                tokens.push({
                    value: description.substring(startPos, endPos),
                    isEntity: true,
                    entityType: entity.type
                });
                
                lastPos = endPos;
            }

            if (lastPos < description.length) {
                tokens.push({ value: description.substring(lastPos) });
            }

            return tokens;
        },
        normalizedString(s) {
            return s.replace(/ /g, '').split('').sort().join('');
        },
        setHighlightedToken(token, databasematchId) {
            if (token && databasematchId) {
                this.highlightedToken = {
                    originalValue: token.value,
                    normalizedValue: this.normalizedString(token.value),
                    id: databasematchId
                };
            } else {
                this.highlightedToken = null;
            }
        },
        getHighlightClass(token, databasematchId) {
            if (!token.isEntity) return '';
            if (this.highlightedToken 
                && this.normalizedString(token.value) === this.highlightedToken.normalizedValue 
                && databasematchId === this.highlightedToken.id) {
                return 'highlighted';
            }
            return '';
        },
        getEntityTypeClass(token) {
            if (!token.entityType) return '';  // return empty if no entityType
            return `entity-type-${token.entityType}`;
        },
        // The function that handles adding additional rows.
        toggleAdditionalRow(tendermatchId) {
            this.$nextTick(() => {
                const wrapperElement = this.$refs[`wrapper-${tendermatchId}`][0];
                if (this.showAdditionalRowsFor.includes(tendermatchId)) {
                    const height = wrapperElement.scrollHeight;
                    wrapperElement.style.maxHeight = `${height}px`;
                } else {
                    wrapperElement.style.maxHeight = '0px';
                }

                // Reset the max-height after the transition is done
                wrapperElement.addEventListener('transitionend', () => {
                    if (this.showAdditionalRowsFor.includes(tendermatchId)) {
                        wrapperElement.style.maxHeight = 'auto';
                    }
                }, { once: true });
            });

            if (this.showAdditionalRowsFor.includes(tendermatchId)) {
            this.showAdditionalRowsFor = this.showAdditionalRowsFor.filter(id => id !== tendermatchId);
            } else {
            this.showAdditionalRowsFor.push(tendermatchId);
            }
        },
        logout() {
            this.$cookies.remove('tm-token');
            this.$router.push('/login');
        },
        getMatchKeyword(tender_keyword, database_desc, unique_id) {
            if (database_desc.includes(tender_keyword)) {
                return unique_id + tender_keyword
            } else {
                return false
            }
        },
        getMatchKeywordDB(db_keyword, tender_desc, unique_id) {
            const split_desc = tender_desc.split(/\s+/)

            for (let index = 0; index < split_desc.length; index++) {
                let tender_keyword = split_desc[index];
                if (db_keyword.includes(tender_keyword)) {
                    return unique_id + tender_keyword
                }
            }
            return false
        },
        getTenderKeyword(db_keyword, tender_desc, unique_id) {
            const split_desc = tender_desc.split(/\s+/)

            for (let index = 0; index < split_desc.length; index++) {
                let tender_keyword = split_desc[index];
                if (db_keyword.includes(tender_keyword)) {
                    return unique_id + tender_keyword
                }
            }
            return "no_match"
        },
        async changeStatus(tendermatch_obj, status, bulkAction = false) {
            tendermatch_obj.tender_product_match_status = status
            fetch('https://api.tendermatcher.org/backend_api/tendermatch_status/' + tendermatch_obj.id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.token}`
                },
                body: JSON.stringify(tendermatch_obj)
            })

            if (!bulkAction) {
                this.toast.success("The product has been succesfully processed!");
            }
        },
        async bulkChangeStatus(status) {
            // Assuming you have all the items (or at least those that are selected) in a 'items' array or similar
            const selectedTenderMatches = this.getPageOfItems.filter(item => this.selectedItems.includes(item.id));

            for (const tendermatch_obj of selectedTenderMatches) {
                await this.changeStatus(tendermatch_obj, status, true);
            }

            this.toast.success("The products have been succesfully processed!");

            // After all updates, you might want to clear the selection
            this.selectedItems = [];
        },
        // TODO fix this function (the django backend couldn't handle the code provided by chat gpt)
        // async bulkChangeStatus(status) {
        //     // Assuming you have all the items (or at least those that are selected) in a 'items' array or similar
        //     const selectedTenderMatches = this.getPageOfItems.filter(item => this.selectedItems.includes(item.id));

        //     const payload = selectedTenderMatches.map(tendermatch_obj => {
        //         return {
        //             ...tendermatch_obj,
        //             match_status: status
        //         };
        //     });

        //     try {
        //         const response = await fetch('https://api.tendermatcher.org/backend_api/tendermatch_status_bulk/', {
        //             method: 'PUT',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Authorization': `Token ${this.token}`
        //             },
        //             body: JSON.stringify(payload)
        //         });

        //         if (response.ok) {
        //             const responseData = await response.json();
        //             // Handle the successful response if needed

        //             // After all updates, you might want to clear the selection
        //             this.selectedItems = [];
        //             console.log('zzzzzz')
        //             console.log(responseData)
        //         } else {
        //             // Handle errors if any
        //             console.log('gggggg')
        //         }
        //     } catch (error) {
        //         console.error("Error updating items:", error);
        //         // Handle the error or show feedback to the user
        //     }
        // },
        async changeOrder(index, tendermatch_obj, status) {
            // Remove the database match at the specified index and insert it into the first index.
            const new_match = tendermatch_obj.database_matches.splice(index, 1)
            tendermatch_obj.database_matches.splice(0, 0, new_match[0])

            // Update the score of the new top item to be +1 of the second item.
            tendermatch_obj.database_matches[0].score = tendermatch_obj.database_matches[1].score + 1;

            // Update the tendermatch_obj using the tendermatch update view.
            await fetch('https://api.tendermatcher.org/backend_api/tendermatch_order/' + tendermatch_obj.id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.token}`
                },
                body: JSON.stringify(tendermatch_obj)
            }) 

            // We also need to make sure the row is no longer expanded when moved to a different status.
            this.showAdditionalRowsFor = this.showAdditionalRowsFor.filter(id => id !== tendermatch_obj.id);

            await this.changeStatus(tendermatch_obj, status)
        },
        exportMatches() {
            let firstDatabaseMatch

            for (let index = 0; index < this.tendermatches.length; index++) {
                firstDatabaseMatch = this.tendermatches[index].database_matches[0]
                this.json_data_short.push({tender_description: this.tendermatches[index].tender_product_desc,
                                           database_description: firstDatabaseMatch.database_product_desc})
                this.json_data_long.push({tender_description: this.tendermatches[index].tender_product_desc,
                                          db_description: firstDatabaseMatch.database_product_desc,
                                          product_id: firstDatabaseMatch.db_article_numr,
                                          supplier_id: firstDatabaseMatch.product_db_numr,
                                          brand: firstDatabaseMatch.product_db_brand,
                                          category: firstDatabaseMatch.product_db_cat,
                                          price: firstDatabaseMatch.product_db_price})
            }
            console.log(this.json_data_short)
            console.log(this.json_data_long)
        },
        showModal(modal_type) {
            if (modal_type == "export") {
                this.exportMatches()
                this.isExportModalVisible = true;
            } else {
                this.isSettingsModalVisible = true;
            }
        },
        closeModal(modal_type) {
            if (modal_type == "export") {
                // We might need to add a sleep between this export matches and making the modal visible.
                this.exportMatches()
                this.isExportModalVisible = false;
            } else {
                this.isSettingsModalVisible = false;
            }
        },
        onClickHandler(page) {
            this.curPage = page;
            // console.log()
            // const start = (page - 1) * this.selectedOption
            // const end = page * this.selectedOption
            // this.filteredTendermatches = this.tendermatches.slice(start, end)
        },
        buttonStatusFilter(status) {
            this.statusFilter = status
            this.curPage = 1
        },
        setPageOne() {
            this.curPage = 1;
        },
        sortList(s) {
            if(s === this.sortBy) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            }
            this.sortBy = s;
        }
    }
}
</script>

<style scoped>
    h4 {
        padding: 3px;
        margin: 0px;
    }
    .container {
        display: flex;
        flex-direction: row;
        /* overflow: hidden;
        width: 100%; */
    }

    .main-nav {
        position: fixed;
        width: 100%;
        z-index: 999;
    }

    .nav-list {
        /* More playful */
        /* background-color: #00549f; */
        /* More professional */
        background-color: #036;
        box-shadow: 0 0 25px 0 rgba(73,80,87,.1);
    }
    
    .nav-icon a {
        color: #fff!important;
        /* color: #F8F7F3; */
        /* color: #eeebe3; */
    }

    .nav-icon a:hover {
        /* color: #fff; */
        /* color: #F8F7F3; */
        color: #eeebe3;
    }

    .nav-item a {
        color: #fff;
        /* color: #F8F7F3; */
        /* color: #eeebe3; */
    }

    .nav-item a:hover {
        /* color: #fff; */
        /* color: #F8F7F3; */
        color: #eeebe3;
    }

    .sub-nav {
        overflow: visible;
        position: fixed;
        z-index: 999;
        margin-top: 67px;
        width: 100%;
        /* border-bottom: 1px solid #ccc; */
        /* box-shadow: 0 19px 34px -15px #d2d2f2; */
        background-color: #fff;
        border-bottom: 1px solid #eeebe3;
    }

    .donut-container {
        float: left; 
        margin: 17px 17px 17px 90px
    }

    .btn-status-container {
        float: left;
        width: 500px;
        padding-top: 13px;
    }

    .btn-status-filter {
        width: 40%;
        margin: 5px;
        padding: 6px 8px;
        height: 32px;
        background-color: transparent;
        /* border: 2px solid #2c3e50;
        color: #2c3e50; */
        border: 2px solid #036;
        color: #036;
        border-radius: 7px;
        cursor: pointer;
        /* font-family: serif; */
        letter-spacing: 1px;
    }

    .btn-status-filter:hover {
        background-color: #f2f3f4;
        box-shadow: rgba(0, 0, 0, 0.35) 0 0 9px;
    }

    .btn-status-filter.active {
        color: #fff;
        background-color: #036;
    }

    .subnav-rightfloat {
        float: right;
    }

    .cst-layout-container {
        float: left;
        margin-top: 35px;
        margin-right: 30px;
        border: 2px solid #036;
        border-radius: 7px;
        cursor: pointer;
    }

    .subnav-rightfloat-text {
        float: left;
        text-align: right;
        padding: 6px 6px;
        color: #036;
        letter-spacing: 1px;
        /* font-family: serif; */
    }

    .footer-rightfloat-text {
        float: left;
        text-align: right;
        color: #000;
        letter-spacing: 1px;
        padding: 16px 0;
        font-size: small
    }

    .customize-icon {
        float: right;
        padding: 10px 6px;
        color: #036;
    }

    .subnav-rightfloat .paginate-options {
        float: left;
        margin-top: 35px;
        margin-right: 90px;
        margin-left: 30px;
        border: 2px solid #036;
        border-radius: 7px;
    }

    footer .paginate-options {
        float: left;
        margin-right: 90px;
    }

    .custom-select {
        float: left;
        margin: 13px 6px;
    }

    .custom-select .vue-select {
        width: 60px;
        border: 0px;
    }

    footer .custom-select {
        margin: 0;
        padding: 12px 0;
    }

    .bulk-action-container {
        float: left;
        padding-top: 13px;
    }

    .vert-nav {
        display: flex;
        flex-direction: column;
        /* position: fixed;
        top: 0;
        left: 0; */
        height: 100vh;
        width: 210px;
        overflow: auto;
        background-color: #036;
        transition: all 0.3s;
    }

    /* The ul that appears in the vert nav */
    .vert-nav ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    /* The li that appears in the vert nav */
    .vert-nav li {
        text-decoration: none;
        overflow: auto;
        color: #fff;
    }

    .vert-nav a:hover {
        color: #eeebe3;
        text-decoration: none;
    }

    .vert-nav-logo-container {
        padding: 25px 20px;
        border-bottom: 1px solid #eeebe3;
        box-shadow: rgba(238, 235, 227, 0.2) 0px 5px 15px;
        margin-bottom: 30px;
    }

    .vert-nav-logo {
        display: flex;
        align-items: center;
        text-align: left;
        font-family: 'Domine', serif;
        font-size: 20px;
        font-weight: 700;
        text-decoration: none;
        height: 40px;
        color: #FFFFFF;
    }

    .vert-nav-logo svg {
        height: 40px;
        margin-right: 10px;
    }

    .vert-nav-logo span {
        line-height: 1.25rem;
    }

    .vert-nav-mainmenu-container {
        padding: 10px 20px;
        /* margin: 0px 10px; */
    }

    .vert-nav-mainmenu {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 20px;
        font-weight: 400;
        text-decoration: none;
        height: 40px;
        color: #FFFFFF;   
    }

    /* The vert nav main menu icons */
    .vert-nav-mainmenu svg {
        height: 32px;
        width: 32px;
        margin-right: 10px;
    }

    .vert-nav-mainmenu-container.active {
        background-color: #002850;
        border-right: 5px solid #067370;
        /* border-radius: 50px; */
    }
    
    /* The progress bar donut in the vert nav */
    .vert-nav-donut {
        padding: 50px;
    }

    .vert-nav-submenu-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 20px 70px 20px;
        margin-top: auto;
        /* margin: 0px 10px; */
    }

    .vert-nav-submenu {
        overflow: auto;
        text-decoration: none;
        color: #FFFFFF;   
    }

    .vert-nav-submenu svg {
        height: 48px;
        width: 48px;
    }


    .matchlist-container {
        height: calc(100vh - 50px);
        overflow: scroll;
        /* max-height: 100%;
        overflow: auto;
        position: absolute;
        top: 0;
        right: 0; */
    }

    .matchlist-container-sidebar {
        width: calc(100% - 210px);
    }

    .vert-nav-sub {
        padding: 10px 10px 10px 10px;
        margin: 10px 16px 0px 32px;
        border-radius: 10px;
        width: 142px;
    }

    .table-container {
        width: 100%;
        border-spacing: 0px;
        /* border-collapse: collapse; */
        table-layout: fixed;
        /* z-index: 1; */
        position: relative;
    }

    .table-container-horizontal {
        margin-top: 176px;
    }

    tbody .non-expandable-rows {
        background-color: #fff;
        z-index: 2;
        position: relative;
    }

    tbody .non-expandable-rows td {
        border-bottom: 1px solid #eeebe3;
    }

    tbody .non-expandable-rows:hover, .non-expandable-rows.active {
        background-color: #E8EEF4;
    }

    .non-expandable-rows th {
        padding: 15px 10px;
    }

    tbody .non-expandable-rows td {
        padding: 15px 10px;
    }

    thead .non-expandable-rows .table-status {
        padding: 0 10px;
    }

    thead .non-expandable-rows .table-status-hover {
        padding: 0 10px;
        opacity: 0;
    }

    thead .non-expandable-rows .table-status-hover.active {
        padding: 0 10px;
        opacity: 1;
    }

    .expandable-rows {
        box-shadow: rgba(0, 51, 102, 0.25) 0px 15px 30px -6px inset, rgba(0, 0, 0, 0.3) 0px 9px 18px -9px inset, rgba(50, 50, 93, 0.25) 0px -15px 30px -6px inset, rgba(0, 0, 0, 0.3) 0px -9px 18px -9px inset;
        z-index: 1;
        position: relative;
    }

    .expandable-rows tr:not(:last-child) {
        border-bottom: #E8EEF4 solid 1px;
    }

    .expandable-rows tr:last-child {
        border-bottom: none;
    }

    .expandable-rows-td-hidden {
        padding: 0px!important;
    }

    .expandable-rows-table-wrapper {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 1s;
    }

    .expandable-rows-table-wrapper-active {
        transition: max-height 1s;
    }
    
    .expandable-rows-table-wrapper .nested-table {
        opacity: 0;
        transform: translateY(-20px);;
        transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    }

    .expandable-rows-table-wrapper-active .nested-table {
        opacity: 1;
        transform: translateY(0);;
        transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    }

    .table-header {
        background-color: #fff;
        position: sticky;
        top: 0;
        z-index: 3;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px;
    }

    .table-header-horizontal {
        background-color: #fff;
        position: sticky;
        top: 176px;
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px;
    }

    .nested-table {
        width: 100%;
        border-spacing: 0px;
        border-collapse: collapse;
        table-layout: fixed;
    }

    .nested-table td {
        padding: 15px 10px;
    }

    .table-bulk {
        width: 30px;
        padding-left: 20px!important;
    }

    .table-status {
        width: 180px;
        text-align: center;
        padding-right: 20px;
    }

    .table-tender {
        width: calc((100% - 510px)/2);
        text-align: left;
        word-wrap: break-word;
        /* padding: 15px 10px 15px 0px; */
    }

    .table-database {
        width: calc((100% - 510px)/2);
        text-align: left;
        word-wrap: break-word;
    }

    .table-matches {
        width: 230px;
    }

    .table-body {
        line-height: 2.15em;
    }

    .table-bulk-action {
        width: 15px;
        height: 15px;
    }

    .table-bulk-grid {
        display: grid;
        justify-content: center;
    }

    .table-bulk-checkbox {
        --background: #fff;
        /* --border: #d1d6ee;
        --border-hover: #bbc1e1;
        --border-active: #1e2235; */
        --border: #9EA6AF;
        --border-hover: #7E858C;
        --border-active: #001122;
        /* --border-active: #003366; */
        --tick: #fff;
        position: relative;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .table-bulk-checkbox input, .table-bulk-checkbox svg {
        width: 16px;
        height: 16px;
        display: block;
    }
    .table-bulk-checkbox input {
        padding: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        appearance: none;
        outline: none;
        background: #fff;
        border: none;
        cursor: pointer;
        border-radius: 3px;
        transition: border-color 0.3s;
        border: var(--s, 2px) solid var(--b, var(--border));
    }
    .table-bulk-checkbox input:hover {
        --s: 2px;
        --b: var(--border-hover);
    }
    .table-bulk-checkbox input:checked {
        --b: var(--border-active);
    }
    .table-bulk-checkbox svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--stroke, var(--border-active));
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(var(--scale, 1)) translateZ(0);
        width: 16px;
        height: 16px;
    }
    .table-bulk-checkbox.table-bulk-path input:checked {
        --s: 2px;
        transition-delay: 0.4s;
    }
    .table-bulk-checkbox.table-bulk-path input:checked + svg {
        --a: 16.1 86.12;
        --o: 102.22;
    }
    .table-bulk-checkbox.table-bulk-path svg {
        stroke-dasharray: var(--a, 86.12);
        stroke-dashoffset: var(--o, 86.12);
        transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }

    .table-product-title {
        display: flex;
        height: 20px;
    }
 
    .table-product-span {
        font-size: 20px;
        color: #2D3740;
        font-weight: 400;
        margin-right: 10px;
    }

    .table-product-sort {
        display: flex;
        flex-direction: column;
        stroke: #2D3740;
    }

    .table-product-search {
        display: flex;
        min-width: 90%;
        max-width: 95%;
        margin-top: 10px;
        padding: 4px 6px;
        border-radius: 4px;
        border: 1px solid #CAD5E0;
    }

    .table-search-magnifying {
        height: 20px;
        width: 20px;
    }

    .table-search-magnifying svg {
        stroke: #7E858C;
    }

    .table-search-box {
        height: 16px;
        border: 0;
        width: 95%;
        margin-left: 6px;
        padding: 2px;
    }

    .table-matches-title {
        display: flex;
        justify-content: center;
        height: 20px;
    }

    .table-matches-span {
        font-size: 20px;
        color: #2D3740;
        font-weight: 400;
    }

    .table-matches-features {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        height: 30px;
    }

    .table-features-span {
        color: #9EA6AF;
        font-size: 10.24px;
        font-weight: 700px;
        text-align: center;
    }

    .table-matches-circles {
        height: 20px;
        margin-top: 6px;
        margin-left: 10px;
        margin-right: 10px;
        padding: 6px 0;
    }

    .table-status-title {
        display: flex;
        justify-content: center;
        height: 20px;
    }

    .table-status-span {
        font-size: 20px;
        color: #2D3740;
        font-weight: 400;
    }

    .table-status-bulk {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
    .table-status-bulk .vue-select {
        width: 100px;
    }

    thead .table-status-bulk {
        margin-top: 0;
    }

    .table-status-select {
        border: 1px solid #CAD5E0;
        /* color: rgba(153,153,153,0.5);
        text-align: left!important; */
    }

    .icon-status {
        color: #036;
    }

    .table-matches-featureicons {
        display: flex;
        justify-content: space-between;
        height: 40px;
    }

    .table-matches-description {
        width: 65px;
        text-align:center;
    }

    .table-matches-ean {
        width: 40px;
        text-align:center;
    }

    .table-matches-brand {
        width: 40px;
        text-align:center;
    }

    .table-matches-category {
        width: 45px;
        text-align:center;
    }

    .table-status-regular {
        display: flex;
        transition: all 0.3s;
    }

    .non-expandable-rows:hover .table-status-regular, .table-status.active .table-status-regular {
        display: none;
        transition: all 0.3s;
    }

    tbody .non-expandable-rows .table-status-hover-container {
        display: none;
    }

    .expandable-rows .table-status-hover-container {
        margin: 0 10px;
    }

    .table-status-hover svg {
        height: 24px;
        width: 24px;
    }

    .expandable-rows .table-status-hover {
        margin-right: 40px;
    }

    tbody .non-expandable-rows:hover .table-status-hover-container, .table-status.active .table-status-hover-container, thead .non-expandable-rows .table-status-hover-container {
        display: flex;
        transition: all 0.3s;
        justify-content: center;
        align-items: center;
        color: #001122;
    }

    .table-status-hover {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .table-status-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 30px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 4px;
        font-weight: 400;
        font-size: 12.4px;
        text-decoration: none;
    }

    .yellow.table-status-button {
        color: #ffae00;
        background: #fff9e5;
    }

    .blue.table-status-button {
        color: #003366;
        background: #f1f4f8;
    }

    .red.table-status-button {
        color: #730606;
        background: #f4dede
    }

    .green.table-status-button {
        color: #067306;
        background: #e4f5f5;
    }

    .purple.table-status-button {
        color: #470673;
        background: #eee4f5;
    }

    .table-change-status {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
    }

    .table-change-status:hover {
        border-radius: 50%;
    }

    .yellow.table-change-status:hover {
        background-color: #fff9e5;
    }

    .blue.table-change-status:hover {
        background-color: #f1f4f8;
    }

    .red.table-change-status:hover {
        background-color: #f4dede;
    }

    .purple.table-change-status:hover {
        background-color: #eee4f5;
    }

    .purple.arrow-up.table-change-status {
        background-color: #eee4f5;
        border-radius: 50%;
    }


    .purple.table-change-status.arrow-down svg {
        transition: 0.5s;
    }

    .purple.table-change-status.arrow-up svg {
        transform: rotate(180deg);
        transition: 0.5s;
    }

    .total-match-circle {
        width: 20px;
        height: 20px;
        background-color: #000;
        border-radius: 9999px;
        margin-right: 7px;
        color: #fff;
        text-align: center;
        cursor: pointer;
    }

    /* Description */
    .desc-match-circle {
        width: 20px;
        height: 20px;
        background-color: #56C596;
        border-radius: 9999px;
        margin-right: 7px;
        color: #fff;
        text-align: center;
        cursor: pointer;
    }

    /* Brand */
    .brand-match-circle {
        width: 20px;
        height: 20px;
        background-color: #00d2ff;
        border-radius: 9999px;
        margin-right: 5px;
        color: #fff;
        text-align: center;
        cursor: pointer;
    }

    .supplier-match-circle {
        width: 20px;
        height: 20px;
        background-color: #EA3C53;
        border-radius: 9999px;
        margin-right: 5px;
        color: #fff;
        text-align: center;
        cursor: pointer;
    }

    .cat-match-circle {
        width: 20px;
        height: 20px;
        background-color: #b100cd;
        border-radius: 9999px;
        color: #fff;
        text-align: center;
        cursor: pointer;
    }

    .match-alternatives {
        float: left;
        width: 10%;
        text-align: left;
    }

    .match-item {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 45px;
        /* font: 200 20px/1.5 Helvetica, Verdana, sans-serif; */
        border-bottom: 1px solid #ccc;
        padding: 20px 0 10px 0;
    }

    /* .match-item:nth-child(odd) {
        background-color: rgba(17, 24, 31, 0.1);
    } */

    .match-item a {
        text-decoration: none;
        color: #2c3e50;
    }

    .match-item a:hover {
        text-decoration: none;
        color: black;
    }

    .match-item:nth-child(odd):hover {
        background-color: rgb(17, 24, 31, 0.15);

    }

    .match-item:nth-child(even):hover {
        background-color: #f6f6f6;
    }

    .match-item:first-child:hover {
        background-color: rgba(17, 24, 31, 0.1);
    }

    .match-text {
        margin-top: 5px;
    }
    
    .match-hover {
        background: linear-gradient(90deg, rgba(0,84,159,1) 0%, rgba(0,51,102,1) 100%);
        border-radius: 10px;
        padding: 6px 6px 3px 6px;
        color: white!important;
        cursor: pointer;
    }

    .match-keyword {
        margin: 0px 2px;
    }

    .match-keyword-dynamic {
        color: black;
        transition: 0.5s;
    }

    .matches-dashboard-footer {
        position: fixed;
        bottom: 0;
        right: 0;
        width: calc(100% - 210px);
        overflow: visible;
        letter-spacing: 1px;
        transition: all 0.3s;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.35) 0px -5px 15px;
    }

    .footer-hidden {
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }

    .footer-hidden:hover {
        visibility: visible;
        opacity: 1;
    }

    .token-base {
        cursor: pointer;
        background-color: none;
        border-radius: 10px;
        padding: 6px 6px 6px 6px;
        margin: 0px 2px;
    }

    .token-space {
        cursor: none;
    }

    .entity {
        color: black;
        transition: 0.5s;
    }

    .non-entity {
        color: #6c7278;
    }

    .highlighted {
        color: white;
        transition: 0.5s; 
    }

    .entity-type-brand.highlighted {
        background-color: #036;
        transition: 0.5s;
    }

    .entity-type-descriptive.highlighted {
        background-color: #067370;
        transition: 0.5s;
    }

    .entity-type-ean.highlighted {
        background-color: #730606;
        transition: 0.5s;
    }

    .entity-type-material.highlighted {
        background-color: #3C0673;
        transition: 0.5s;
    }
</style>