<template>
    <Transition name="settings-modal-fade">
        <div class="settings-modal-backdrop" @click="close">
            <div class="settings-modal" @click.stop="">
                <header class="settings-modal-header">
                    <h1>Customize the Tender Matcher Dashboard</h1>
                    <button type="button" class="btn-close" @click="close">x</button>
                    
                    <!-- <button type="button" class="btn-close" @click="close">x</button> -->
                </header>

                <section class="settings-modal-body">
                    <h3>Activate sidebar</h3>
                    <input type="checkbox" v-model="sidebarChecked">
                    <!-- <download-csv :data="jsonDataShort"><button class="btn-primary btn-export">Export</button></download-csv> -->
                </section>


                <footer class="settings-modal-footer">
                    <h3>Hide footer</h3>
                    <input type="checkbox" v-model="hideFooter">
                    <!-- <download-csv :data="jsonDataLong"><button class="btn-primary btn-export">Export</button></download-csv> -->
                </footer>
            </div>
        </div>
    </Transition>
</template>

<script>
  export default {
    name: 'SettingsModal',
    props: [],
    data() {
        return {
            token: '',
            sidebarChecked: true,
            hideFooter: false,
        }
    },
    methods: {
        // onExport(all_columns) {
        //     // We can export all columns or only the description columns.
        //     if (all_columns) {

        //     } else {

        //     }
        // },
        close() {
            this.$emit('update:parent', this.sidebarChecked);
            this.$emit('update:parent2', this.hideFooter);
            this.$emit('close');
        },
    },
  };
</script>

<style>
    .settings-modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index:9999999;
    }

    .settings-modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }

    .settings-modal-header,
    .settings-modal-footer {
        padding: 35px 120px;
        display: flex;
    }

    .settings-modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #003366;
        justify-content: center;
    }

    .settings-modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
        justify-content: flex-end;
    }

    .settings-modal-body {
        position: relative;
        padding: 35px 120px;
        justify-content: center;
    }

    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: #003366;
        background: transparent;
    }

    .btn-green {
        color: white;
        background: #003366;
        border: 1px solid #003366;
        border-radius: 2px;
    }

    .settings-modal-fade-enter-from,
    .settings-modal-fade-leave-to {
        opacity: 0;
    }

    .settings-modal-fade-enter-active,
    .settings-modal-fade-leave-active {
        transition: opacity .5s ease;
    }

    .btn-export {
        padding: 10px 30px;
    }

</style>