<template>
    <div>
        <nav>
            <div class="nav-list">
                <!-- <router-link to="/"><div class="float-left nav-icon"><img src="../assets/logo_tendermatcher.svg" /></div></router-link> -->
                <router-link to="/" class="float-left nav-icon">
                    <font-awesome-icon icon="code-compare"/>
                    <span>Tender Matcher</span>
                </router-link>
                <router-link to="/" class="float-left nav-item nav-home">
                    Home
                </router-link>
                <a @click=showModal class="float-left nav-item nav-upload">
                    Upload
                </a>
                <a @click=logout class="float-right nav-item nav-logout">
                    Logout
                </a>
            </div>
        </nav>

        <UploadModal v-show="isModalVisible" @close="closeModal"/>

        <div class="tender-list-container">
            <div class="tender-item" v-for="tender in tenders" :key="tender.id">
                <div class="tender-progress">
                    <vc-donut
                        :size="100"
                        :thickness="20"
                        :auto-adjust-text-size="false"
                        :sections=getDonutSection(tender.tender_progress_todo,tender.no_of_products)>
                        {{ getPercentageComplete(tender.tender_progress_todo,tender.no_of_products)  }}%
                    </vc-donut>
                </div>
                <div class="tender-info-container">
                    <div class="tender-info">
                        <span class="tender-maintext">{{tender.title.substr(0,tender.title.length - 4)}}</span>
                        <span class="tender-subtext">{{tender.publisher_fullname}} | {{tender.publisher_company}}</span>
                    </div>
                    <div class="tender-info">
                        <span class="tender-maintext">Published</span>
                        <span class="tender-subtext">{{ tender.date.substr(8, 2) }}-{{ tender.date.substr(5, 2) }}-{{ tender.date.substr(0, 4) }}</span>
                    </div>
                    <div class="tender-info">
                        <span class="tender-maintext">Products</span>
                        <span class="tender-subtext">{{ tender.no_of_products }}</span>
                    </div>
                </div>
                <div class="tender-start">
                    <router-link :to="`/tender/${tender.id}`" class="tender-start-button">
                        Start Matching
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UploadModal from "./UploadModal.vue"

export default {
    name: "TendersDashboard",
    // components: { UploadModal, CircleProgress, },
    components: { UploadModal, },
    data() {
        return {
            tenders: [],
            // Data for the progress donut
            tender_progress_chart: [],
            token: '',
            isModalVisible: false,
        }
    },
    created() {
        if(this.$cookies.isKey('tm-token')) {
            this.token = this.$cookies.get('tm-token');

            fetch('https://api.tendermatcher.org/backend_api/tender/', {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${this.token}`
                }
            }).then(resp => resp.json()).then(resp => this.tenders = resp.reverse()).catch(error => console.log(error))
        } else {
            this.$router.push('/login');
        }
    },
    filters: {
        reverse: function(data) {
            return data.reverse();
        }
    },
    methods: {
        logout() {
            this.$cookies.remove('tm-token');
            this.$router.push('/login');
        },
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        getPercentageComplete(todo, total) {
            let numComplete = total - todo
            let percentageComplete = numComplete / total * 100
            
            return Math.round(percentageComplete)
        },
        getDonutSection(todo, total) {
            let numComplete = total - todo
            let percentageComplete = numComplete / total * 100
            let percentageTodo = todo / total * 100

            let tender_progress_chart = [{label: 'completed ' + numComplete + '/' + total,
                                              color: '#067370', value: percentageComplete}, 
                                          {label: 'todo ' + todo + '/' + total,
                                              color: '#F5F5F5', value: percentageTodo}]
            
            return tender_progress_chart
        },
    },
}
</script>

<style scoped>

.nav-list {
        list-style-type: none;
        margin: 0;
        padding: 20px 40px;
        overflow: auto;
        background-color: #fff;
    }

    .nav-list::after {
        content: '';
        position: absolute;
        width: calc(100% - 82px);
        height: 0px;
        left: 40px;
        top: 79px;
        border: 1px solid #CAD5E0;
    }

    .nav-icon {
        font-family: 'Domine', serif;
        font-size: 39px;
        font-weight: 700;
        display: block;
        text-decoration: none;
        height: 40px;
        color: #001122;
        margin-right: 40px;
    }

    .nav-icon svg {
        height: 40px;
        margin-right: 20px;
    }

    .nav-item {
        height: 40px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        font-size: 25px;
        color: #2D3740;
        text-decoration: none;
    }

    .nav-home {
        margin-right: 40px;
        background-color: #F5F5F5;
    }

    .nav-upload {
        background-color: #003366;
        color: #FFFFFF;
    }

    .nav-logout {
        background-color: #F5F5F5;

    }
    
    .tender-list-container {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 20px 41px;
    }

    .tender-item {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 40px;
        margin: 20px 0px;
        background: #FFFFFF;
        /* box-shadow: 0px 0px 22px -8px #CAD5E0;9EA6AF */
        box-shadow: 0px 0px 22px -8px #9EA6AF;
        border-radius: 20px;
    }

    .tender-progress {
        margin-right: 80px;
    }

    .tender-info-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex: 1;
    }

    .tender-info {
        display: flex;
        flex-direction: column;
        min-width: 200px;
        max-width: 320px;
        text-align: left;
        justify-content: center;
        margin-right: 80px;
    }

    .tender-maintext {
        font-size: 25px;
        font-weight: 700;
        line-height: 2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tender-subtext {
        font-size: 25px;
        font-weight: 400;
        color: #6C7278;
        line-height: 2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tender-start {
        display: flex;
        padding: 10px 0px;
        margin-left: 80px;
    }

    .tender-start-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(90deg, #067370 0%, #003366 100%);
        border-radius: 20px;
        font-weight: 700;
        font-size: 25px;
        color: #FFFFFF;
        text-decoration: none;
        padding: 20px;
    }
</style>


