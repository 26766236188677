<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

    #app {
        font-family: 'Open Sans', sans-serif;
        text-align: center;
        color: #001122;
        line-height: 1rem;
    }

    body {
        margin: 0px!important;
    }

    a {
        cursor: pointer;
    }

    .float-left {
        float: left;
    }
    
    .float-right {
        float: right;
    }

    /* Text inside progress donut on tenderdashboard. */
    .tender-progress .cdc-text {
        font-size: 25px!important;
        font-weight: 700;
    }

    .vert-nav .cdc {
        box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.3)!important;
    }

    /* Text inside progress donut on matchesdashboard. */
    .vert-nav-donut .cdc-text {
        font-size: 20px!important;
        font-weight: 400;
    }

    .btn-primary {
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        border: 0;
        padding: 10px 0;
        border-radius: 5px;
        background-color: #005497;
        transition: 0.5s;
    }

    .btn-primary:hover {
        background-color: #ffd324;
        color: #000;
    }

    /* The paginations css without vertical navbar */
    .subnav-rightfloat .pagination-container {
        float: left;
        border-radius: 7px;
        padding: 6px 0px;
        border: 2px solid #036;
        margin-top: 35px;
    }

    .paginate-buttons {
        width: 40px;
        height: 40px;
        margin-inline: 5px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        border-radius: 4px;
    }

    .back-button {
        width: 70px;
    }

    .next-button {
        width: 70px;
    }

    /* The paginations css with the vertical navbar */
    footer .pagination-container {
        float: left;
        margin: 10px;
    }

    footer .paginate-buttons {
        width: 30px;
        height: 30px;
    }

    footer .back-button {
        width: 70px;
    }

    footer .next-button {
        width: 70px;
    }

    /* Pagination css regardless of vertical navbar */
    .back-button svg {
        transform: rotate(180deg);
    }

    .active-page {
        background-color: #036!important;
        color: #fff!important;
    }

    .paginate-buttons:hover {
        background-color: #eeebe3;
    }
    .active-page:hover {
        background-color: #024;
        color: #fff;
    }
    .back-button:active,
    .next-button:active {
        background-color: #dedede;
    }

    .vue-input input {
        /* font-size: 1rem!important; */
        /* color: #036;
        cursor: pointer!important; */
    }

    table .vue-input input {
        color: rgba(0, 51, 102, 0.5);
        color: #7E858C;
        text-align: center;
        font-weight: 400;
        font-size: 12.4px;
        height: 16px;
    }

    table .vue-input {
        padding: 6px;
    }

    table .vue-dropdown {
        color: #036;
        letter-spacing: 1px;
    }

    .vue-select-header {
        cursor: pointer;
    }

    .vue-input input {
        text-align: center;
    }

    ::-moz-placeholder{
        opacity:1
    }

    .sub-nav .icon.arrow-downward {
        color: #036!important;
        border-width: 6px 6px 0!important;
        border-color: #036 transparent transparent!important;
    }

    .vue-dropdown {
        border: 0!important;
    }

    .vue-select.direction-bottom .vue-dropdown {
        border-bottom-left-radius: 7px!important;
        border-bottom-right-radius: 7px!important;
        box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.2);
        border-top: 2px solid #036!important;
    }

    .vue-dropdown-item.highlighted {
        background-color: #036!important;
        color: #fff;
    }

    footer .vue-input input {
        color: #000!important;
        font-size: small!important;
    }

    footer .icon.arrow-downward {
        color: #000!important;
        border-width: 6px 6px 0!important;
        border-color: #000 transparent transparent!important;
    }

    .export-modal-fade-enter-from,
    .export-modal-fade-leave-to {
        opacity: 0;
    }

    .export-modal-fade-enter-active,
    .export-modal-fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .settings-modal-fade-enter-from,
    .settings-modal-fade-leave-to {
        opacity: 0;
    }

    .settings-modal-fade-enter-active,
    .settings-modal-fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .Vue-Toastification__toast--success {
        background-color: #036!important;
        bottom: 50px!important;
    }

</style>
