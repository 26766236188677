import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueCookies from 'vue-cookies'
import JsonCSV from 'vue-json-csv'

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

import JwPagination from 'jw-vue-pagination';

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

import VueSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCodeCompare, faFileArrowDown, faCircle, faSliders, faHouse, faMagnifyingGlass,
         faFilter, faListCheck, faSquareCheck, faSquareXmark, faArrowsSpin, faSort,
         faSortUp, faSortDown, faGear } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck, faCircleQuestion, faCircleXmark, faCircleRight } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

library.add(faCodeCompare, faFileArrowDown, faCircle, faSliders, faHouse, faFilter, faListCheck,
            faSquareCheck, faCircleCheck, faCircleQuestion, faCircleXmark, faCircleRight,
            faSquareXmark, faArrowsSpin, faMagnifyingGlass, faSort, faSortUp, faSortDown, faGear)

let the_app = createApp(App)

the_app.component('downloadCsv', JsonCSV)
the_app.component('font-awesome-icon', FontAwesomeIcon)
the_app.component('font-awesome-layers', FontAwesomeLayers)
the_app.component('jw-pagination', JwPagination)
the_app.component("vue-select", VueSelect)
the_app.use(router)
the_app.use(VueCookies)
the_app.use(VueAwesomePaginate)
the_app.use(Donut)
the_app.use(Toast, {position: 'bottom-right', timeout: 5000, toastContainerClasses: ['custom-toast-container']})
the_app.mount('#app')
