<template>
    <div class="login-page login-image">
        <div class="container">
            <div class="h1">Sign In</div>
            <form class="login-form" @submit.prevent>
                <div class="form-input">
                    <input id="Email" v-model="username" type="email" placeholder="Email">
                    <!-- <label for="Email">Email</label>  -->
                </div>
                <div class="form-input">
                    <input v-model="password" type="password" id="Password" placeholder="Password">
                    <!-- <label for="Password">Password</label>    -->
                </div>
                <div class="checkbox-container">
                    <input type="checkbox" v-model="rememberMe">
                    <div class="text-checkbox">Remember me</div>
                </div>
                <div class="button-area">
                    <button class="btn-primary btn-login" @click="login()" >Submit</button>
                </div>
                <div v-if="wrongCredentials">
                    <p class="login-error">Sorry, the login credentials <br/> seem incorrect.</p>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoginPage",
    data() {
        return {
            wrongCredentials: false,
            rememberMe: false,
            username: '',
            password: '',
        }
    },
    created() {
        if(this.$cookies.isKey('tm-token')) {
            this.$router.push('/');
        }
    },
    methods: {
        isRememberMe() {
            return this.rememberMe === true;
        },
        login() {
            this.wrongCredentials = false;
            if (this.username && this.password) {
                fetch('https://api.tendermatcher.org/auth/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({username: this.username, password: this.password})
                })
                .then(resp => resp.json())
                .then( resp => {
                    if (resp.token) {
                        // If the user has checked the "remember me" checkbox we
                        // keep the cookie for 7 days, else only for the session.
                        if (this.isRememberMe()) {
                            this.$cookies.set("tm-token", resp.token, "7d");
                        } else {
                            this.$cookies.set("tm-token", resp.token, 0);
                        }
                        this.$router.push('/');
                    } else {
                        this.wrongCredentials = true;
                    }
                })
            }
            
            
        }
    }
}
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }

    .login-page {
        align-items: center;
        display: flex;
        height: 100vh;
        background: #003366;
        background: linear-gradient(90deg, #067370 0%, #003366 50%, #090673 100%);
        height: 100%;
        position: absolute;
        width: 100%;
    }

    /* .login-image {
        background-image: url("../assets/logo_ip2.png");
        background-repeat: repeat;
    } */

    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border: 1px solid #d3d3d3;
        padding: 6rem 5.5rem;
        border-radius: 10px;
        background: #fefefe;
    }

    input {
        font-family: inherit;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 0;
        font-size: 16px;
        color: #000;
        border-radius: 5px;
    }

    input[type="email"],
    input[type="password"] {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #aaaaaa;
        padding-left: 10px;
        padding-right: 5px;
        padding-top: 5px;
    }

    .h1 {
        color: black;
        opacity: 0.8;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.1em;
        text-align: center;
        position: absolute;
        transform: translateY(-25px);
    }

    .login {
        overflow: hidden;
        background-color: white;
        padding: 40px 30px 30px 30px;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 400px;
        box-shadow: 5px 10px 10px rgba(green, 0.2);
    }

    .form-input  {
        display: block;
        border-radius: 5px;
        font-size: 16px;
        background: white;
        width: 100%;
        border: 0;
        padding: 10px 10px;
        margin: 15px -10px;
    }

    .checkbox-container {
        display: flex;
    }

    .text-checkbox {
        color: #aaa;
        font-size: 16px;
        letter-spacing: -0.00933333em;
        font-weight: 300;
        margin-left: 10px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    input[type="checkbox"] {
        cursor: pointer;
        margin: 0;
        height: 22px;
        position: relative;
        width: 22px;
        -webkit-appearance: none;
        transition: all 180ms linear;
    }

    input[type="checkbox"]:before {
        border: 1px solid #aaa;
        background-color: #fff;
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 2px;
        transition: all 180ms linear;
    }

    input[type="checkbox"]:checked:before {
        background: linear-gradient(198.08deg, #ffd324 45.34%, #e281e7 224.21%);
        border: 1px solid #ffd324;
        color: #000;
        background-color: #000;
    }

    input[type="checkbox"]:after {
        content: "";
        border: 2px solid #fff;
        border-right: 0;
        border-top: 0;
        display: block;
        height: 4px;
        left: 4px;
        opacity: 0;
        position: absolute;
        top: 6px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width: 12px;
        transition: all 180ms linear;
    }

    input[type="checkbox"]:checked:after {
        opacity: 1;
    }

    .button-area {
        display: flex;
        margin-top: 20px;
    }

    .btn-login {
        width: 80px;
        margin-top: 10px;
        margin-left: -5px;
    }

    .login-error {
        margin-block-start: 2em;
        margin-block-end: 0em;
        color: red;
    }
</style>
