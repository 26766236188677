<template>
    <div>
        <nav>
            <ul class="nav-list">
                <li class="nav-item-left nav-icon fa-2xl"><router-link to="/"><font-awesome-icon icon="code-compare"/> Tender Matcher </router-link></li>
                <li class="nav-item-left nav-item nav-item-home"><router-link to="/">Home</router-link></li>
                <li class="nav-item-right nav-item"><a class="active" @click="logout()">Logout</a></li>
            </ul>
        </nav>
        <!-- <h1>The DatabaseMatchesDashboard</h1>
        {{ this.$route.params }}
        {{ tendermatchDesc }}
        <p>hallo</p>
        {{ databasematches }} -->


        <div class="container">
            <div class="sub-nav">
                <h1>The DatabaseMatchesDashboard</h1>
            </div>
            <div class="match-list">
                <div class="match-item">
                    <div class="match-status">
                        <h4>Correct Match</h4>
                    </div>
                    <div class="tender-match2">
                        <h4>Tender Product</h4>
                    </div>
                    <div class="database-match2">
                        <h4>Database Product</h4>
                    </div>
                </div>


                <div class="match-item" v-for="(databasematch, index) in databasematches" :key="databasematch.id">
                    <div class="match-status">
                        {{index}}
                        <!-- idk yet what to give the change order -->
                        <a @click="changeOrder(index)" title="Match">
                            <font-awesome-layers class="icon-status fa-xl">
                                <font-awesome-icon class="icon-status" :icon="['far', 'circle-check']"/>
                            </font-awesome-layers>
                        </a>
                    </div>
                    <div class="tender-match2 match-text">
                        <span 
                            v-for="token in tokenize(this.tendermatchDesc, databasematch.entity_matches, 'tender')" 
                            :key="token.value"
                            :class="getHighlightClass(token, databasematch.id)"
                            @mouseenter="setHighlightedToken(token, databasematch.id)"
                            @mouseleave="setHighlightedToken(null, null)">
                            {{ token.value }}
                        </span>
                    </div>
                    
                    <div class="database-match2 match-text">
                        <span 
                            v-for="token in tokenize(databasematch.product_db_desc, databasematch.entity_matches, 'db')" 
                            :key="token.value"
                            :class="getHighlightClass(token, databasematch.id)"
                            @mouseenter="setHighlightedToken(token, databasematch.id)"
                            @mouseleave="setHighlightedToken(null, null)">
                            {{ token.value }}
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <button class="btn-primary btn-nomatch" @click="changeStatus(2)">No matches</button>
                <button class="btn-primary btn-nomatch" @click="back_one_page()">Go Back</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DatabaseMatches",
    data() {
        return {
            tendermatch: null,
            tendermatchDesc: '',
            databasematches: [],
            token: '',
            tendermatchID: this.$route.params.match,
            // Track the current highlighted token
            highlightedToken: null 
        }
    },
    async created() {
        if(this.$cookies.isKey('tm-token')) {
            this.token = this.$cookies.get('tm-token');

            await fetch('https://api.tendermatcher.org/backend_api/tendermatch_single/' + this.tendermatchID, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${this.token}`
                }
            }).then(resp => resp.json()).then(resp => this.tendermatch = resp).catch(error => console.log(error))

            console.log(this.tendermatch)
            this.tendermatchDesc = this.tendermatch.product_desc
            this.databasematches = this.tendermatch.database_matches
        } else {
            this.$router.push('/login');
        }
    },
    methods: {
        tokenize(description, entityMatches, mode) {
            let tokens = [];
            let lastPos = 0;

            // Flatten the entityMatches to account for multiple positions
            let flattenedEntities = [];
            entityMatches.forEach(entity => {
                entity[`${mode}_entity_positions`].forEach(pos => {
                    flattenedEntities.push({
                        value: entity[`${mode}_entity_value`],
                        positions: pos
                    });
                });
            });

            // Sort entity matches based on the positions
            flattenedEntities.sort((a, b) => a.positions[0] - b.positions[0]);

            for (let entity of flattenedEntities) {
                let startPos = entity.positions[0];
                let endPos = entity.positions[1];

                if (lastPos < startPos) {
                    tokens.push({ value: description.substring(lastPos, startPos) });
                }

                tokens.push({ value: description.substring(startPos, endPos), isEntity: true });

                lastPos = endPos;
            }

            if (lastPos < description.length) {
                tokens.push({ value: description.substring(lastPos) });
            }

            return tokens;
        },
        setHighlightedToken(token, databasematchId) {
            if (token && databasematchId) {
                    this.highlightedToken = {
                    token: token,
                    id: databasematchId
                };
            } else {
                this.highlightedToken = null;
            }
        },
        getHighlightClass(token, databasematchId) {
            if (!token.isEntity) return '';
            if (this.highlightedToken 
                && token.value === this.highlightedToken.token.value 
                && databasematchId === this.highlightedToken.id) {
                return 'highlighted';
            }
            return '';
        },
        logout() {
            this.$cookies.remove('tm-token');
            this.$router.push('/login');
        },
        back_one_page() {
            this.$router.push('/tender/' + this.tendermatch.tender);
        },
        async changeStatus(status) {
            console.log(status)
            // tendermatch = JSON.stringify(tendermatch)
            this.tendermatch.match_status = status
            console.log(this.tendermatch)
            await fetch('https://api.tendermatcher.org/backend_api/tendermatch_status/' + this.tendermatch.id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.token}`
                },
                body: JSON.stringify(this.tendermatch)
            })

            this.back_one_page()
        },
        async changeOrder(index) {
            const new_match = this.databasematches.splice(index, 1)
            this.databasematches.splice(0, 0, new_match[0])

            this.tendermatch.database_matches = this.databasematches

            await fetch('https://api.tendermatcher.org/backend_api/tendermatch_order/' + this.tendermatch.id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.token}`
                },
                body: JSON.stringify(this.tendermatch)
            })

            await this.changeStatus(1)
            this.back_one_page()
        },
    },
}
</script>

<style scoped>
    h4 {
        padding: 3px;
        margin: 0px;
    }
    .container {
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .sub-nav {
        border-bottom: 1px solid #ccc;
    }

    .match-list {
        overflow: hidden;
        position: relative;
        width: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .match-item {
        overflow: hidden;
        position: relative;
        width: 100%;
        /* font: 200 20px/1.5 Helvetica, Verdana, sans-serif; */
        border-bottom: 1px solid #ccc;
        padding: 10px 0px;
    }

    .match-item:nth-child(odd) {
        background-color: rgba(17, 24, 31, 0.1);
    }

    .match-item a {
        text-decoration: none;
        color: #2c3e50;
    }

    .match-item a:hover {
        text-decoration: none;
        color: black;
    }

    .match-item:nth-child(odd):hover {
        background-color: rgb(17, 24, 31, 0.15);

    }

    .match-item:nth-child(even):hover {
        background-color: #f6f6f6;
    }

    .match-item:first-child:hover {
        background-color: rgba(17, 24, 31, 0.1);
    }

    .match-text {
        margin-top: 5px;
    }
    
    .match-status {
        float: left;
        width: 10%;
    }

    .icon-status {
        padding: 0px 5px;
    }

    .tender-match2 {
        float: left;
        width: 45%;
    }

    .database-match2 {
        float: left;
        width: 45%;
    }

    .btn-nomatch {
        padding: 10px;
        margin: 15px;
    }

    .highlighted {
        background: linear-gradient(90deg, rgba(0,84,159,1) 0%, rgba(0,51,102,1) 100%);
        border-radius: 10px;
        padding: 6px 6px 3px 6px;
        color: white!important;
        cursor: pointer;
        margin: 0px 2px;
        color: black;
        transition: 0.5s;
    }
</style>