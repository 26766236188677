<template>
    <transition name="export-modal-fade">
        <div class="modal-backdrop" @click="close">
            <div class="modal" @click.stop="">
                <header class="modal-header">
                    <h1>Export the database matches</h1>
                    <button type="button" class="btn-close" @click="close">x</button>
                </header>

                <section class="modal-body">
                    <h3>Export the description columns</h3>
                    <download-csv :data="jsonDataShort"><button class="btn-primary btn-export">Export</button></download-csv>
                </section>


                <footer class="modal-footer">
                    <h3>Export all the columns</h3>
                    <download-csv :data="jsonDataLong"><button class="btn-primary btn-export">Export</button></download-csv>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
  export default {
    name: 'ExportModal',
    props: ['jsonDataShort', 'jsonDataLong'],
    data() {
        return {
            token: '',
        }
    },
    methods: {
        // onExport(all_columns) {
        //     // We can export all columns or only the description columns.
        //     if (all_columns) {

        //     } else {

        //     }
        // },
        close() {
            this.$emit('close');
        },
    },
  };
</script>

<style>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index:9999999;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        transition: all 500ms ease-in-out;
    }

    .modal-header,
    .modal-footer {
        padding: 35px 120px;
        display: flex;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #003366;
        justify-content: center;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
        justify-content: flex-end;
    }

    .modal-body {
        position: relative;
        padding: 35px 120px;
        justify-content: center;
    }

    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: #003366;
        background: transparent;
    }

    .btn-green {
        color: white;
        background: #003366;
        border: 1px solid #003366;
        border-radius: 2px;
    }

    .export-modal-fade-enter-from,
    .export-modal-fade-leave-to {
        opacity: 0;
    }

    .export-modal-fade-enter-active,
    .export-modal-fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .btn-export {
        padding: 10px 30px;
    }

</style>