import { createRouter, createWebHashHistory } from 'vue-router'
import TendersDashboard from '../components/TendersDashboard.vue'
import MatchesDashboard from '../components/MatchesDashboard.vue'
import DatabaseMatches from '../components/DatabaseMatches.vue'
import LoginPage from '../components/LoginPage.vue'

const routes = [
  {
    path: '/',
    name: 'TendersDashboard',
    component: TendersDashboard
  },
  {
    path: '/tender/:tender',
    name: 'MatchesDashboard',
    component: MatchesDashboard
  },
  {
    path: '/tender/matches/:match',
    name: 'DatabaseMatches',
    component: DatabaseMatches
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
