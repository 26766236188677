<template>
    <Transition name="upload-modal-fade">
        <div class="upload-modal-backdrop" @click="close">
            <div class="upload-modal" @click.stop="" :class="{'upload-modal-transition': uploading}">
                <header class="upload-modal-header">
                    <h1>Upload a file</h1>
                    <button type="button" class="btn-close" @click="close">x</button>
                </header>

                <section class="upload-modal-body">
                    <input type="file" @change="onFileSelected">

                    <div v-if="wrongFiletype">
                        <p class="upload-error" :class="{'shake': wrongUpload}">Please select a csv file that is smaller than 10mb</p>
                    </div>
                </section>


                <footer class="upload-modal-footer">
                    <button class="btn-primary" @click="onUpload">Upload</button>

                    <div v-if="wrongColumnnames">
                        <p class="upload-error" :class="{'shake': shakeColumnError}">Please include a "Description" or <br/> "SupplierID" column in the tender</p>
                    </div>
                    <Transition name="match-loading">
                        <div v-if="uploading">
                            <h4>Matching algorithm has started</h4>
                            <div class="lds-dual-ring"></div>
                        </div>
                    </Transition>
                </footer>
            </div>
        </div>
    </Transition>
</template>

<script>
import axios from 'axios';

  export default {
    name: 'Uploadupload-Modal',
    data() {
        return {
            selectedFile: null,
            wrongFiletype: false,
            wrongUpload: false,
            wrongColumnnames: false,
            shakeColumnError: false,
            token: '',
            userID: '',
            tenderID: '',
            uploadResp: null,
            uploading: false,
        }
    },
    methods: {
        onFileSelected(event) {
            this.wrongColumnnames = false;

            // We only accept one file that is smaller than 10mb and in csv format.
            const fileSize = event.target.files[0].size;
            const fileType = event.target.files[0].type;
            if (fileSize > 10000000 || (fileType != "text/csv" && fileType != "text/tab-separated-values")) {
                this.wrongFiletype = true;
            } else {
                this.wrongFiletype = false;
                this.selectedFile = event.target.files[0];
            }
        },
        async onUpload() {
            // Make the error shake if the user attempts to upload an invalid file.
            if (this.wrongFiletype) {
                this.wrongUpload = true;
                setTimeout(() => {
                    this.wrongUpload = false;
                }, 110);
            } else {
                if(this.$cookies.isKey('tm-token')) {
                    this.token = this.$cookies.get('tm-token');

                    const headers = {
                        'Authorization': `Token ${this.token}`,
                        'Content-Type': 'multipart/form-data',
                    }

                    const formData = new FormData();
                    formData.append('title', this.selectedFile.name);
                    formData.append('publisher', this.userID);
                    formData.append('url', this.selectedFile);

                    this.uploading = true
                    await axios.post('https://api.tendermatcher.org/backend_api/tender/', formData, { headers })
                            .then(resp => this.tenderID = resp.data['id']).catch(error => this.uploadResp = error);
                    // setTimeout(() => {
                    //     this.uploading = false
                    //     this.$router.push("/tender/76");
                    // }, 17000)

                    // If the user did not provide the correct column names in
                    // the tender, give the user feedback of the error.
                    if (this.uploadResp && this.uploadResp.response.status == 415) {
                        this.wrongColumnnames = true;
                        setTimeout(() => {
                            this.shakeColumnError = true;
                        }, 110);
                        setTimeout(() => {
                            this.shakeColumnError = false;
                        }, 400);
                    } else {
                        console.log("123");
                        console.log(this.tenderID);
                        console.log("456");
                        // We maybe need to wait for the matching algorithm before routing.
                        setTimeout(() => {
                            this.$router.push("/tender/" + this.tenderID);
                            // this.$emit('uploaded', this.tenderID);
                        }, 500);
                    }
                } else {
                    this.$router.push('/login');
                }
            }
        },
        close() {
            this.$emit('close');
        },
    },
    created() {
        if(this.$cookies.isKey('tm-token')) {
            this.token = this.$cookies.get('tm-token');

            fetch('https://api.tendermatcher.org/backend_api/user_info/', {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.token}`,
            },
            }).then(resp => resp.json()).then(resp => this.userID = resp['id'])
        } else {
            this.$router.push('/login');
        }
    },
  };
</script>

<style>
    .upload-modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index:9999999;
    }

    .upload-modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        transition: all 500ms ease-in-out;
        width: 550px;
        height: 400px;
        overflow-y: hidden;
    }

    .upload-modal-transition {
        height: 500px;
    }

    .upload-modal-header,
    .upload-modal-footer {
        padding: 35px 120px;
        display: flex;
    }

    .upload-modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #003366;
        justify-content: center;
    }

    .upload-modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
        justify-content: flex-end;
    }

    .upload-modal-body {
        position: relative;
        padding: 35px 120px;
        justify-content: center;
    }

    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: #003366;
        background: transparent;
    }

    .btn-green {
        color: white;
        background: #003366;
        border: 1px solid #003366;
        border-radius: 2px;
    }

    .upload-modal-fade-enter-from,
    .upload-modal-fade-leave-to {
        opacity: 0;
    }

    .upload-modal-fade-enter-active,
    .upload-modal-fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .upload-error {
        color: red;
        margin-bottom: 0px;
        margin-top: 30px;
    }

    .shake {
        animation-name: shaker;
        animation-duration: 0.5s;
        transform-origin: 50% 50%;
        animation-timing-function: linear;
    }

    @keyframes shaker {
        0% { transform: translate(6px, 0); }
        25% { transform: translate(-6px, 0); }
        50% { transform: translate(6px, 0); }
        75% { transform: translate(-6px, 0); }
        100% { transform: translate(6px, 0); }
    }

    .match-loading-enter-from,
    .match-loading-leave-to {
        opacity: 0;
    }

    .match-loading-enter-active,
    .match-loading-leave-active {
        transition: opacity 1s ease;
    }

    .lds-dual-ring {
        display: inline-block;
        width: 50px;
        height: 50px;
        margin: 0 auto 0 auto;
        padding-right: 15px;
    }

    .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 32px;
        height: 32px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #036;
        border-color: #036 transparent #036 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
    
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>